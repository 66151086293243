<template functional>
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 2H3C1.895 2 1 2.895 1 4V18C1 19.105 1.895 20 3 20H15C16.105 20 17 19.105 17 18V6.828C17 6.298 16.789 5.789 16.414 5.414L13.586 2.586C13.211 2.211 12.702 2 12.172 2H8"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 7H13C12.448 7 12 6.552 12 6V2"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 1H5C4.448 1 4 1.448 4 2V7L6 6L8 7V2C8 1.448 7.552 1 7 1Z"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 11H13"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 14.5H13"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMenu2",
};
</script>

<style></style>
